:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}



.faqsContenedorPrincipal{
    padding: 1rem;
    margin: 1rem;
    background-color: rgb(20, 19, 19, 0.7);
    color: white;
}