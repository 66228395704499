:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
    font-size: 1.25rem;
}

#subPaginaArriendoFadeIn {
    animation: fadeInAnimation ease 4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}



#subPaginaArriendocontenedorMapa{
    margin-bottom: 2rem;

}




#subPaginaPropiedadTituloPagina{
    text-align: justify;


}


.subPaginaPropiedadContenedor{
    margin: 2rem;
    text-align: center;
    color:black;
}
.vp-player-layout { margin: 0; padding: 0; }

#block-yui_3_17_2_1_1592273484742_11130 {
    padding: 0;
}

.subPaginaPropiedadVideo{
    
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
    
}

.subPaginaPropiedadContenedorVideo{
    
    width: 100%;

    margin-top: 1rem;
    margin-bottom: 1rem;
}

.elementoListaLugares{
    color:var(--azulEspecial);
    
}
#contenedorMapaSubProp{
    width: 100%;
    height: 350px;


}
.columnaGridPropiedadUnicaPerfil{
    width: 300px;
    padding: 0px;
    
}

#subPaginaArriendoBotonContacto{
    position: relative;
    bottom: 0px;
    margin: 2px;
}
.subPaginaArriendoImagenPerfil{
    max-width: 100%;
    padding: 0.3rem;
    border-radius: 15px;
    
}


.imagenGrid{
    max-width: 100%;
    padding: 0.3rem;
    transition: transform 1s; 
    border-radius: 15px;
}
.imagenGrid:hover{
    transform:scale(1.1);
}

.columnaInformacionDerecha{
    padding-right: 10rem;
    


}


.columnaGridPropiedadDuo{
    width: 50%;
    padding: 0px;
    
}
.columnaGridPropiedadUnica{
    width: 50%;
    padding: 0px;
    
}


.subPaginaPropiedadMobileContainerFotoUnica{
    width: 100%;
    padding-top: 0.5rem;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;

}


.subPaginaPropiedadMobileFotoUnica{
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}


.subPaginaPropiedadMobileContenedorVideo{
    width: 100%;
    
    padding:0px;
    margin-left:0px;
    margin-right:0px;
}

#subPaginaPropiedadMobileContenedorBotonContacto{
    text-align: center;
}

#subPaginaPropiedadMobileBotonContacto{
    width: 51%;
    margin-top: 10px;
}


.subPaginaPropiedadMobileParrafo{
    padding: 1rem;


}



.subPaginaPropiedadMobileVideo{
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
    
}

.subPaginaPropiedadMobileContenedor{
    
    text-align: center;
    color:black;
}


.subPaginaPropiedadTitulo{
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}