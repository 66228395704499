:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}




#textoFinal{
    text-align: center;
    color: var(--azulEspecial);
    
}