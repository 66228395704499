@import url(/src/styles/subPaginaArriendo.css);
@import url(/src/styles/contacto.css);
@import url(/src/styles/mainPage.css);
@import url(/src/styles/seleccionarPropiedad.css);
@import url(/src/styles/seleccionarTipoPropiedad.css);
@import url(/src/styles/footer.css);
@import url(/src/styles/navegacion.css);
@import url(/src/styles/avisopropiedad.css);
@import url(/src/styles/faqs.css);
@import url(/src/styles/contacto.css);
@import url(/src/styles2/main.css);
:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}
iframe{
    volume: silent;
}

.contenedorNoDisponible{
    height: 45vh;
    width: 100%;
    text-align: center;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.contenedorNoDisponibleCarousel{
    
    width: 100%;
    text-align: center;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;


}







.inputForma{
    margin:0.5rem;

    
    width: 100%;
}
.inputFormaMobile{
    margin:0.1rem;
    
    width: 100%;
}



.containerCargando{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}


.contenedorInformacionLadoDerechoPropiedad{
    text-align: left;
    color: var(--azulEspecial);
    padding: 1rem;



}








.columnaGridPropiedad{
    width: 30%;
    padding: 0px;
    
}








#lacasa{
    background-image: url("./images/casa.JPG");

}










#textoAzul{
    color:var(--azulEspecial);
}
.textoAzul{
    color:var(--azulEspecial);
}






#wrapperLinksNavegacion{
    align-items: right;
    
}








.columnaNavegacion{
    text-align: center;
    color:var(--azulEspecial);
}




.contenedorDescripcionNumerica{
    background-color: grey;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 5px;
    color: white;
    text-align: left;
    
    padding-top: 10px;
    
}
.contenedorNombrePrecioPropidedad{
    background-color: grey;
    color: white;
    padding: 3px;
    text-align: left;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 5px;

}


.contenedorAcordionSubpaginaPropiedad{
    background-color: white;
    




}




#divTextoArriendosStgo{
    background-color: transparent;
    color: black;
    padding:10px
}

#accordionArriendos{
    margin:20px;
}

.estilosMaps{
    height:auto;
    width:100%
}

#gmap_canvas {
    height:300px;    
    width:100%;
}


.mapa {
    margin-top: 10px;
    height:auto;    
    width:100%;
}




#contenedorAbout{
    background-color: rgb(46, 46, 46);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 15px;
    border-radius: 5px;
    color: white;
    padding: 10px;
    text-align: justify;

}


#ContenedorPaginaArriendos{
    background: rgb(34,193,195);
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(37,206,204,1) 17%, rgba(39,217,211,1) 38%, rgba(41,230,219,1) 70%, rgba(45,253,234,1) 100%);
}


.contenedorBorrar{
    display: flex;
    
    align-items: center;
}

.contenedosImagenesExterior{
    text-align: center;
    width: 50%;
    height:auto;
    margin: 0 auto;
}


.contenedorTabla{
    border-color: black;
    border-width: 5px;
    border-style: solid;
}


.imagenCarousel{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width:300px;
    height: auto;
}

#textoNavegacion{
    padding: 10px;
}

.linkSimple{
    color:rgba(45,149,253,1)

}



#carouselHome{
    height:400px;
    bottom: 0; 
    width: 100%; 
    height: 50px; 
}
.divCarousel{
    width: 100%; 
    text-align: center;
    height: 50px;    
    margin-bottom: 10px;

}


