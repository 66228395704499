@import url('https://fonts.googleapis.com/css2?family=Gloock&family=IBM+Plex+Serif&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab&display=swap');
@import url(/src/styles2/subPaginaPropiedad2.css);
@import url(/src/styles2/carousel2.css);


:root{
    --azulEspecial:rgba(45,149,253,1);
    --gris_claro: rgb(231, 231, 231);
    --tituloPrincipal: 1rem;
    --alturaFila :80vh;
    --altura_imagen_fondo:130vh;
    --margin: 1rem;
}


#v2mainContainer{
    position:relative;
    background-image: "../images/fondoPrincipal.jpg";
    height: 100vh;
    width: 100%;
}

#main_page_content_container{
z-index:1;
    position:absolute;
    width: 100%;
}
#main_page_superior_container{
    width: inherit;
    display:grid;
    height: 80vh;
    grid-template-columns: 3fr 4fr;
    grid-template-areas: "cita carousel_arriendos_contenedor";
}



#cita_h1{
    font-size: 2rem;
    font-family:  serif;
}
#cita_h2{
    font-size: 1rem;
    font-family:  serif;
}
#cita_h3{
    font-size: .8rem;
    font-family: serif;
}
#cita_hr{
    border:3px solid rgb(0,0,0);
    width: 80%;
}
#cita{
    display: flex;
    text-align: end;
    flex-direction: column;
    grid-area: cita;
    justify-content: center;
    align-items: flex-end;
height: 75%;
}

.the_wrapper{
    grid-area:carousel_arriendos_contenedor ;
    display: flex;
flex-direction: column;
	margin: .5rem;
	width: 60%;
    align-items: center;
	justify-content: center;
    background-color: white;
    border-radius: 1.5rem;
    filter: drop-shadow(5px 5px 5px rgb(88, 88, 88));
}

#carousel_arriendos_contenedor{
	display: flex; 
	justify-content: center;
	align-items: center;
}
.carousel_h1{
    font-family: serif;
	text-align: center;
	font-size: 1.5rem;
}




.paginaPrincialImagenAviso{
    border-radius: 3px;
    width: 100%;
    height: 50vh;
    object-fit: contain;
}



#fondoPrincipal{
    position: absolute;
    width: 100%;
    height: var(--altura_imagen_fondo);
    z-index:0;
}
#fondoPrincipal > img{
	object-fit:cover;
	width:inherit;
	height:inherit;
    -webkit-mask: linear-gradient(to bottom, white 70%, transparent);

}


#main_page_middle_container{
	position:relative;
	display: grid;
	grid-template-areas:"mapa_main_page contenedor_direccion carousel_ventas_contenedor";
	grid-template-columns: 4fr 3fr 5fr;
	margin-top: 3rem;
	height: 85vh;
}
#mapa_main_page{
	grid-area: mapa_main_page;
	height:inherit;
	padding: 1rem;
}
#mapa_main_page> iframe{
	margin-top: 3.7rem;
	height:60%;
}
#contenedor_direccion{
	grid-area:contenedor_direccion;
	display:flex;
	flex-direction: column;
	justify-content: center;
}

#contenedor_direccion >h1 {
    font-size: 2rem;
    font-family:  serif;
}
#contenedor_direccion >h2 {
    font-size: 1rem;
    font-family:  serif;
}
#contenedor_direccion >h3 {
    font-size: .8rem;
    font-family:  serif;
}

.the_wrapper2{
    grid-area:carousel_ventas_contenedor ;
    display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: center;
    border-radius: 1.5rem;
}
#carousel_ventas_contenedor{
	grid-area:carousel_ventas_contenedor;
	margin: 2rem;
    	border-radius: 1.5rem;
    	filter: drop-shadow(5px 5px 5px rgb(88, 88, 88));
	display: flex; 
	justify-content: center;
	align-items: center;
	background-color: var(--azulEspecial);
	color: white;
}
#carousel_ventas_contenedor> h1{
	text-align:center;
    font-size: 2rem;
    font-family:  serif;
}
#main_page_faq_container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items:center;
	height: 70vh;
}
#main_page_faq_container_texto{
    position: absolute;
    width: 100%;
    height: 25%;
    backdrop-filter: blur(5px);
    background-color: rgba(61, 61, 61, 0.653);
	display:flex;
	align-items:center;
	justify-content:center;
}
#main_page_faq_container>img{
	height: 100%;width:100%;	
	object-fit:cover;

}
#main_page_faq_container_texto>h1{
    font-family:  serif;
	color:white;
}
