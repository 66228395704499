:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}

#tituloFiltrarComuna{
	font-family: serif; 
	font-size: 1rem;	
	margin: 1rem;
}
.tituloMostrarPropiedades{

	font-family: serif; 
	font-size: 2rem;	
	margin:1rem;

}

.formaFiltrado{
	font-size: .7rem;
}
.botonFiltrar{
	border-radius: 5px;
	font-size: .7rem;
	background-color: var(--azulEspecial);
	border-color: var(--azulEspecial);
	color:white;
}


.avisoPropiedadMobileContenedorImagenAviso{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-left: 1rem;

}
.avisoPropiedadMobileImagenAviso{
   
    width: 100%;
    border-radius: 5px;
    align-content: center;
    align-items: center;
}


.avisoPropiedadMobileContenedorBotonAviso{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem 0.5rem 2rem;
}







.avisoPropiedadContenedorBotonAviso{
    display: flex;
  justify-content: center;
  align-items: center;

}
.avisoPropiedadContenedorDireccion{
	display:flex;
	align-items:center;
	font-size: .7rem;
}
.aviso_propiedad_container{
	display:grid;
	grid-template-areas:"aviso_propiedad_imagen_container  aviso_propiedad_info_container";
	gap: .5rem;
	grid-template-columns: 1fr 2fr;
}
.aviso_propiedad_imagen_container{
	grid-area: aviso_propiedad_imagen_container;
	display:flex;
	justify-content: right;
}

.aviso_propiedad_info_container{
	grid-area:aviso_propiedad_info_container;

}
.avisoPropiedadImagenAviso{
    margin-left: 10px;
    width: 70%;
    border-radius: 5px;
    align-content: center;
    align-items: center;
}
