:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
     --gris_claro: rgb(231, 231, 231);
	--gris_oscuro:#212529;
   --alturaFila :80vh;
}




#mostrar_disponibles_container{
	display: grid;
	grid-template-areas: "mostrar_disponibles_filtrado mostrar_disponibles_avisos";
	grid-template-columns: 1fr 7fr;
}
#mostrar_disponibles_filtrado{
	grid-area:mostrar_disponibles_filtrado;
}
#mostrar_disponibles_avisos{
	grid-area:mostrar_disponibles_avisos;
}

.linkSimple{
    text-decoration:"none"
}

.paginaPrincipalMobileFilaSuperior{
    background-color: var(--gris_oscuro);
    color:white; 
    padding: 0px;
    text-align: center;
    margin: 0.3rem;
    padding-top: 1rem;
}

#paginaPrincipalFadeIn {
    animation: fadeInAnimation ease 4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#paginaPrincipalContenedorFaqs{
    background-size:cover;
    background-image: url("../images/faqsBackground.jpeg");
    background-repeat: no-repeat;
    width: 100%;
    height: var(--alturaFila);
    display: flex;
    align-items: center;
    justify-content: center;    
}

#paginaPrincipalMobileContenedorFaqs{
    background-size:cover;
    background-image: url("../images/faqsBackground.jpeg");
    background-repeat: no-repeat;
    width: 105%; 
    height: var(--alturaFila);
    display: flex;
    align-items: center;
    justify-content: center;
    
}


#paginaPrincipalMobileContenedorLinksFaqs{
    text-align: center;
    background-color: var(--gris_oscuro);
    color: white;
    width: 100%;
	padding: .5rem;
	font-size: 1.5rem;
	font-family:serif;
}







#paginaPrincipalContenedorLinksFaqs{
    text-align: center;
    background-color: white;
    color: var(--azulEspecial);
    width: 30%;
}
#paginaPrincipalContenedorLinksFaqs:hover {
    border-style: dashed;
    border-color: var(--azulEspecial);
    border-width: 0.3rem;
}


#paginaPrincipalTituloVentasNuevas{
    text-align: center;
}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

#paginaPrincialColumnaIzquierdaSuperior{
    color:var(--azulEspecial);
    background-color:white;
    padding:0px;
    margin-left: 2rem;
}

#paginaPrincialCarouselSuperior{
  height: 10vh;
  width: 70%;
  margin: 0px;
}


#paginaPrincialCarouselInferior{
    height: 10vh;
    width: 70%;
    margin: 0px;
    color: var(--azulEspecial);
  }

.paginaInicialContenedorImagenAviso{
    height: 50vh;
    text-align: center;
    
}
.paginaInicialContenedorImagenAvisoMobile{
    height: 40vh;
    text-align: center;
    
}
.paginaPrincialImagenAvisoMobile{
    margin-left: 10px;
    border-radius: 5px;
    
    width: 100%;
    height: inherit;
    object-fit: contain;
}
.paginaPricipalContenedorInformacionCarousel{
    width: 70%;
    padding: 10px;
    margin: auto;
    color: white;
}

#paginaPrincipalMobileFondoSuperior{
    background-color: white;
   
}


#paginaPrincipalFondoSuperior{
    background-color: white;
    background: linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(45,149,253,1) 100%);
   
}

#paginaPrincipalContenedorBlancoDerechoSuperior{
    background-color: white;
    color:var(--azulEspecial);
    margin:auto; 
    margin-top: 30px;
    padding: 20px; 
    font-size: 20px;
    text-align: center;
}

#paginaPrincipalTituloArriendosNuevos{
    text-align: center;
	font-family: serif;
    color:white;
}

#paginaPrincipalContenedorVentasPaginaPrincipal{
    
    background: white; 
    color:white;
    

}



#paginaPrincipalMobileContenedorVentasPaginaPrincipal{
    
    background: white; 
    text-align: center;
    color:white;
    
    

}

#paginaPrincipalContenedorBlancoIzquerdoInferior{
    background-color:white;
    color:rgba(45,149,253,1);
    margin-top: 30px;
    margin-right: 15px;
    padding: 20px; 
    padding: 20px; 

}

#paginaPrincipalMobileContenedorBlancoIzquerdoInferior{
    background-color:white;
    color:rgba(45,149,253,1);
    
    padding: 20px; 
    padding: 20px; 
    text-align: center;

}
