

:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}

.navegacionColumna{
    text-align: center;
    color:var(--azulEspecial);
	font-size:.65rem;
	text-decoration: none;
	color:white;
}
#titulo_mobile{
	text-align: center;
}

.conColor{
    color:var(--azulEspecial) !important;
}

.navegacionColumna>a{
	font-size:.65rem;
	text-decoration: none;
	color:white;
}

#barra_superior{
}
#barra_inferior{
}
.ddown_item{
	display: flex;
	align-items:center;
	height: 3vh;
	font-size: .7rem;
}

#texto_main> a{
	font-size: 1rem;
	text-decoration: none;
	color:black;
	margin-left: 1rem;
}

.NavegacionMobileFila{
    text-align: center;

    color:var(--azulEspecial);
    margin:auto;
    width: 50%;

}
.NavegacionMobileFilaMobile{
    text-align: center;
    font-size:0.8rem;
    color:var(--azulEspecial);
    margin:auto;
    width: 50%;

}
.navbar-toggler{
	padding:0;
	font-size:1rem;

}
.nav-link{
	font-size:0.8rem;
	text-decoration: none;
}
#NavegacionMobileContenedorFilasDescriptivas{
    text-align: center;
    
}
