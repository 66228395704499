:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}




.seleccionarTipoPropiedadColumnaImagen{
    position:  relative ; 
    padding:10px;

}
.seleccionarTipoBordeAzul{
    border-style: solid transparent;

}



.seleccionarTipoBordeAzul:hover{
    border-color: var(--azulEspecial);
    border-style: solid;
    border-width: 10px;

}



.seleccionarTipoImagenFondo{
    width:100%;
    height: auto;
    filter: none;
    
    transition: all 1s ease-in-out; 
}


.seleccionarTipoPropiedadTextoEnImagen{
  display: block;
  position:  absolute ; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(1.375rem + 1.5vw);;
  background-color: white;
  padding-left: 5px ;
padding-right: 5px ;
  vertical-align: middle;
  text-decoration: none; 

}

.seleccionarTipoImagenFondo:hover + .seleccionarTipoPropiedadTextoEnImagen{
    display: block;
    color:var(--azulEspecial);

}



.seleccionarTipoPropiedadMobileTextoEnImagen{
    display: block;
    position:  absolute ; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(1.375rem + 1.5vw);;
    background-color: white;
    padding-left: 5px ;
    padding-right: 5px ;

    color:var(--azulEspecial);

    text-decoration: none; 
  
  }
  
  
