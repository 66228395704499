:root{
    --azulEspecial:rgba(45,149,253,1);
    --gris_claro: rgb(231, 231, 231);
    --tituloPrincipal: 1rem;
    --alturaFila :80vh;
    --altura_imagen_fondo:130vh;
    --margin: 1rem;
}
.carousel_principal{
	width: 100%;
	height: 100%;
}
#the_wrapper{
	display: flex; 
	justify-content: center;
	align-items: center;
}
.tarjeta_carousel{
	margin: .5rem;
}
.texto_carousel{
}
.texto_carousel>h3{
	font-family: serif; 
	font-size: 1.2rem;
}
.texto_carousel>p{
	font-family: serif; 
	font-size: .7rem;
}

