:root{
    --azulEspecial:rgba(45,149,253,1);
    --gris_claro: rgb(231, 231, 231);
    --tituloPrincipal: 1rem;
    --alturaFila :80vh;
    --altura_imagen_fondo:130vh;
    --small_margin: 1rem;
--text_size_small: .7rem;
}

#contenedor_cuerpo_propiedad{
	display: grid;
	grid-template-areas: "galeria_imagenes ubicacion_interes";
	grid-template-columns: 3fr 1fr;
}
#galeria_imagenes{
	grid-area:galeria_imagenes	;
	padding: 1rem;
	background-color: var(--gris_claro);

}

#galeria_imagenes> h1{
	font-family:serif;	
	font-weight: bold;
	font-size: 1.5rem;
}

#ubicacion_interes{
	grid-area:ubicacion_interes;
	background-color: var(--azulEspecial);
	color:white;
	padding: 1rem;
	
}

#contenedor_gmap{
	height: 60vh;
	width:100%;;

}

#ubicacion_interes>h1{
	font-family:serif;	
	font-weight: bold;
	font-size: 1.5rem;
}
#lista_lugares_interes{
}
#lista_lugares_interes>li{
	font-family:serif;	
	font-size: 1rem;
	color:white;
}
#contenedor_tarjeta{
	height: 80vh;
	width:100%;
	padding-inline: 5rem;
	padding-top: 2rem;
	margin-bottom: 5rem;
}
.info_div{
	font-size: var(--text_size_small);
}
#informacion_tarjeta>hr{
	margin: .5rem;
}

#hr_divisor{
	color: black; 
	size: 5px;
	margin: 0;
}
#titulo_video{
	text-align:center;
}
#video_iframe{
	width: 100%;
	height: 100vh;
}

#meta_propiedad_tarjeta{
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}
.par_imagen_valor{
	display: grid;
	grid-template-areas: "imagen_par valor_par";
	grid-template-columns: 1fr 3fr;
	height: 3vh;
	gap: 0px .5rem;
}
.imagen_par{
	height: inherit;
	object-fit:cover;
	grid-area:imagen_par;
}

.valor_par{
	grid-area:valor_par;
	font-size: .65rem;
	
}

#tarjeta{
	display: grid;
	height: inherit;
	grid-template-areas: "imagen_tarjeta informacion_tarjeta";
	grid-template-columns: 3fr 4fr;
	gap: 0px 1rem;
	background-color: rgb(238, 238, 238);
	border:solid .5px;
}
#titulo_propiedad_tarjeta{
	font-family:serif;	
	font-weight: bold;
	font-size: 1.5rem;

}

#imagen_tarjeta{
	grid-area:imagen_tarjeta;
	height:inherit;
	width: 100%;
}
#imagen_tarjeta > img{
	object-fit: cover;
	height:inherit;
	width:100%;
	
}
#informacion_tarjeta{
	grid-area:informacion_tarjeta;
	display: flex; 
	flex-direction: column;
	justify-content: space-evenly;
	height:100%;
}
#info_div_contenedor_botones{
	display: flex;
	justify-content: space-evenly;
}

#info_div_contenedor_botones>button{
	border-radius: 5px;
	border-color:var(--azulEspecial );
	background-color:var(--azulEspecial );
	color: white;
	font-size: var(--text_size_small);

}

.imagenes_propiedad{
	display: grid; 
	gap: 5px;
	grid-template-columns: repeat(3, 1fr);
}

.imagen_galeria{
	width: 100%;
	height:auto; 
}

