:root{
    --azulEspecial:rgba(45,149,253,1);
    --tituloPricipal: 1rem;
}

.inputForma:required{
	border: 1px dashed red;

}
.recaptchaContainer{
	padding:0.5rem;
}
.buttonForma{
	margin:0.5rem;
}
.recaptchaContainerMobile{
	padding:0.5rem;

}
